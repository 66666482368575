<template>
    <v-dialog 
        class="base-alert-dialog" 
        v-on="$listeners"
        v-bind="$attrs"
        :max-width="width"
        v-if="translates && translates[langUrl]"
    >
        <div class="poup-request">
            <div class="poup-request__sended">
                <div class="poup-request__sended__title">{{ name }}, {{ translates[langUrl].title[lang] }}</div>
                <div class="poup-request__close" @click="close"></div>
                <div class="poup-request__sended__text">{{ translates[langUrl].text[lang] }}</div>
                <BaseButton
                    class="button-fill"
                    style="width:100%"
                    @click="create"
                >{{ translates[langUrl].button_start[lang] }}</BaseButton>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    import store from '@/store';
    import moment from '@/plugins/moment';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BaseButton from '../../components/common/BaseButton.vue'
    import BaseTextField from '../../components/common/BaseTextField.vue'
    import BaseTextArea from '../../components/common/BaseTextArea.vue'

    export default {
        name: 'PopupRequest',
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextField,
            BaseTextArea
        },
        data: () => ({
            secondsLeft: 0,
            intervalId: undefined,
            langUrl: 'PopupRequest',
        }),
        props: {
            text: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            buttonText: {
                type: String,
            },
            width: {
                type: String,
                default: '450'
            }
        },
        computed: {
        ...mapState('settings', ['lang']),
        ...mapState('translates', {
            translates: state => state.entities
        }),
        },
        async mounted() {
        },
        methods: {
            close() {
                this.$emit('close')
            },
            create() {
                this.$emit('create')
            }
        }
    }
</script>

<style lang="scss">
    .poup-request {
        position: relative;
        max-width: 600px;
        width: 100%;
        padding: 20px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        border-radius: 12px;
        @media all and (max-width: 768px) {
            padding: 36px 20px;
        }
        &__page-name {
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
            margin-bottom: 30px;
        }
        &__save {
            width: 100%;
        }
        &__personal-info {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #808080;
            margin-top: 12px;
            margin-left: 5px;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__sended {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title {
                font-weight: 600;
                font-size: 20px;
                line-height: 140%;
                color: #273155;
                margin-bottom: 16px;
            }
            &__text {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #616D89;
                text-align: left;
                letter-spacing: -0.02em;
                color: #A9AEB2;
                margin-bottom: 32px;
            }
        }
    }
</style>
